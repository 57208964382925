<template>
    <Card class="card-custom">
        <template #header>
            <Toolbar>
                <template #start>
                    <h3>
                      {{ $t('fingerprint_detection_setting') }}
                    </h3>
                    <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('fingerprint_detection_hint')"></Badge>
                </template>
                <template #end>
                    <div class="d-flex align-center">
                        <div class="mr-1 font-weight-bold">
                            {{ $t('ip_detection') }}
                        </div>
                        <v-switch hide-details color="green accent-4"
                        inset class="mt-0 ml-1 custom-switch "></v-switch>
                        <Badge value="?" severity="info" class="info-badge" v-tooltip.left="$t('id_dt_hint')"></Badge>
                    </div>
                </template>
            </Toolbar>
        </template>
        <template #content>
            <DataTable
                    :rows="50"
                    :scrollable="true"
                    :loading="loadingFlag" 
                      stripedRows class="p-datatable-sm">
                      <template #empty>
                        {{$t('no_data')}}
                    </template>
                      <Column field="id" :header="$t('parameter')">
                      </Column>
                    </DataTable>
        </template>
    </Card>
</template>

<script>
    export default {
        name: 'MultiaccFingerDetection',
        data() {
            return {
                loadingFlag: false,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>