<template>
    <Card class="card-custom">
        <template #header>
            <Toolbar>
                <template #start>
                    <h3>
                      {{ $t('multiacc_rule_logic') }}
                    </h3>
                    <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('multi_rule_hint')"></Badge>
                </template>
                <template #end>
                </template>
            </Toolbar>
        </template>
        <template #content>
            <div class="options">
                <div class="options__item">
                    <div class="options__content">
                        <div class="options__text">
                            <span>
                               {{ $t('multi_auto_block') }} 
                            </span>
                            <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('multi_auto_block_hint')"></Badge>
                        </div>
                        <div class="options__switcher">
                            <SelectButton v-model="multi_bonus_reward" :options="options" />
                        </div>
                    </div>
                </div>
                <div class="options__item">
                    <div class="options__content">
                        <div class="options__text">
                            <span>
                               {{ $t('multi_bonus_reward') }} 
                            </span>
                            <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip="$t('multi_bonus_reward_hint')"></Badge>
                        </div>
                        <div class="options__switcher">
                            <SelectButton v-model="multi_auto_block" :options="options" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>

    export default {
        name: 'MultiaccRulesLogic',
        data() {
            return {
                multi_auto_block: 'Off',
                multi_bonus_reward: 'Off',
                options: ['Off', 'On'],
            }
        },
    }
</script>

<style lang="scss" scoped>
.options {
    display: flex;
    justify-content:center;
    flex-direction: column;
    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
       
    }

    &__content {
        flex: 0 1 50%;
        @media (min-width:768px){
            display: flex;
            align-items: center;
       }
    }

    &__text {
        flex: 0 1 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width:768px){
            justify-content: flex-end;
             margin-right: 30px; 
        }
    }

    &__switcher {
    }
}

</style>