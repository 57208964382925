<template>
    <v-main>
        <div class="main-content d-flex flex-column mt-0">
            <Card class="card-custom br-0">
                <template #content>
                    <TabView @tab-change="handleTabChange">
                        <TabPanel :header="$t('fingerprint_detection_setting')" >
                            <multiacc-finger-detection v-if="showFingerDetection" class="mt-1" />
                        </TabPanel>
                        <TabPanel :header="$t('multiacc_rule_logic')">
                            <multiacc-rules-logiс v-if="showMultiRules" class="mt-1" />
                        </TabPanel>
                    </TabView>
                </template>
            </Card>
        </div>
    </v-main>
</template>

<script>
import MultiaccFingerDetection from './MultiaccFingerDetection.vue'
import MultiaccRulesLogiс from './MultiaccRulesLogic'
export default {
        name: 'MultiaccSetup',
        components:{
            MultiaccFingerDetection,
            MultiaccRulesLogiс
        },
        data() {
            return {
                showFingerDetection: true,
                showMultiRules: false,
            }
        },
        beforeRouteEnter(to, from, next){
            next(vm=>{
                vm.$store.commit('setPageName', 'multiacc_setup')
            })
        },
        methods:{
            handleTabChange(event) {
                if (event.index === 0) {
                    this.showFingerDetection = true;
                    this.showMultiRules = false;
                } else if (event.index === 1) {
                    this.showFingerDetection = false;
                    this.showMultiRules = true;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>